import Feature from '../../component/Feature';
import Hero from '../../component/Hero';

function Home() {
    return (
        <>
            <main>
                <Hero />
                <Feature />
            </main>
        </>
    );
}
export default Home;
