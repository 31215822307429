const bankAccount = [
    {
        title: 'Checking',
        transactionCount: 8349,
        balance: '2,082.79',
    },
    {
        title: 'Savings',
        transactionCount: 6712,
        balance: '10,928.42',
    },
    {
        title: 'Credit Card',
        transactionCount: 8349,
        balance: '184.30',
    },
];

export { bankAccount };
